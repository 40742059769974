import * as THREE from 'three';
import * as PATHFINDING from 'three-pathfinding';
import { Constants } from './constants.js'

var camera;
var navmesh, pathfinder, groupID, path;
let playerPositioned = false;
var inMovementWithPathfinding = false;
const SPEED = 5;

let content;

const helper = new PATHFINDING.PathfindingHelper();
pathfinder = new PATHFINDING.Pathfinding();
const ZONE = 'level';
const clock = new THREE.Clock();

var events = {};

const playerPosition = new THREE.Vector3();
const targetPosition = new THREE.Vector3();
const raycaster = new THREE.Raycaster();
const mouse = new THREE.Vector2();
const mouseDown = new THREE.Vector2();
let amountPathFinding = 0;
let currentPathFinding = 0;

var enableInputs = true;

export var subscribe = (_event, listener) => {
    if (!events[_event]) {
        events[_event] = [];
    }
    events[_event].push(listener);
}

export var unsubscribe = (_event, listener) => {
    if (events[_event]) {
        events[_event] = events[_event].filter((existingListener) => {
            return existingListener !== listener;
        });
    }
}

var emit = (_event, data) => {
    if (events[_event]) {
        events[_event].forEach((listener) => {
            listener(data);
        });
    }
}

export function init(_camera) {
    camera = _camera;
}

export function initPath(_naveMesh, navMeshLayer) {

    raycaster.layers.set(navMeshLayer);
    content = _naveMesh.parent;

    // const naveMeshGroup = _naveMesh.parent;
    // naveMeshGroup.add(helper);
    helper.reset();

    playerPositioned = false;

    _naveMesh.traverse(child => {
        if (child.isMesh) {
            child.scale.set(1, 1, 1);
            child.material.wireframe = true;
            navmesh = child.clone();
            child.parent.remove(child);
        }
    })



    console.time('createZone()');
    const zone = PATHFINDING.Pathfinding.createZone(navmesh.geometry);
    console.timeEnd('createZone()');
    pathfinder.setZoneData(ZONE, zone);

    // Display navmesh wireframe.
    // content.add(
    //     new THREE.Mesh(
    //         navmesh.geometry,
    //         new THREE.MeshBasicMaterial({ color: 0x808080, wireframe: true })
    //     )
    // );

    // // Display navmesh fill.
    // content.add(
    //     new THREE.Mesh(navmesh.geometry, new THREE.MeshBasicMaterial({
    //         color: 0x606060,
    //         opacity: 0.75,
    //         transparent: true
    //     }))
    // );

    groupID = pathfinder.getGroup(ZONE, playerPosition);

    playerPosition.set(0, 0, 0);
    targetPosition.set(0, 0, 0);
    helper
        .reset()
        .setPlayerPosition(playerPosition)
        .setTargetPosition(playerPosition)
    playerPositioned = true;

    return navmesh;
}

export function updatePointer(posX, posY) {
    mouseDown.set(posX, posY);
}

export function enableControls(value) {
    enableInputs = value;
}


export function findAPath(posX, posY) {

    if (enableInputs == false) return;

    mouse.set(posX, posY);

    camera.updateMatrixWorld();

    raycaster.setFromCamera(mouse, camera);

    const intersects = raycaster.intersectObject(navmesh);

    if (!intersects.length) return;

    if (!playerPositioned) {

        playerPosition.copy(intersects[0].point);
        targetPosition.copy(intersects[0].point);
        helper
            .reset()
            .setPlayerPosition(playerPosition)
            .setTargetPosition(playerPosition)
        playerPositioned = true;
        return;

    }

    playerPosition.set(camera.position.x, camera.position.y - Constants.PLAYER.HEIGHT, camera.position.z);
    targetPosition.copy(intersects[0].point);

    helper
        .reset()
        .setPlayerPosition(playerPosition);


    const targetGroupID = pathfinder.getGroup(ZONE, targetPosition, true);
    const closestTargetNode = pathfinder.getClosestNode(targetPosition, ZONE, targetGroupID, true);

    helper.setTargetPosition(targetPosition);
    if (closestTargetNode) helper.setNodePosition(closestTargetNode.centroid);

    // Calculate a path to the target and store it
    path = pathfinder.findPath(playerPosition, targetPosition, ZONE, groupID);

    if (path && path.length) {

        helper.setPath(path);
        amountPathFinding = path.length;
        inMovementWithPathfinding = true;

    } else {

        const closestPlayerNode = pathfinder.getClosestNode(playerPosition, ZONE, groupID);
        const clamped = new THREE.Vector3();

        // TODO(donmccurdy): Don't clone targetPosition, fix the bug.
        pathfinder.clampStep(
            playerPosition, targetPosition.clone(), closestPlayerNode, ZONE, groupID, clamped);

        helper.setStepPosition(clamped);


    }

}

function movementWithPathFinding(dt) {
    if (!playerPositioned || !(path || []).length) return

    let targetPosition = path[0];
    const velocity = targetPosition.clone().sub(playerPosition);

    if (velocity.lengthSq() > 0.05 * 0.05) {
        velocity.normalize();
        // Move player to target
        playerPosition.add(velocity.multiplyScalar(dt * SPEED));
        emit(Constants.EVENTS_NAME.MOVE_PLAYER_TO_POSITION, playerPosition);
        // helper.setPlayerPosition(playerPosition);
    } else {
        // Remove node from the path we calculated
        if (path.length > 1)
            emit(Constants.EVENTS_NAME.LOOK_AT_TARGET, path[1]);

        path.shift();
        currentPathFinding++
        if (currentPathFinding >= amountPathFinding) {
            inMovementWithPathfinding = false;
            currentPathFinding = 0;
            var data = {
                visible: false,
                pos: new THREE.Vector3(0, 0, 0),
                lookAlt: new THREE.Vector3(0, 0, 0)
            }
            emit(Constants.EVENTS_NAME.POINTER_POSITION, data);
        }
    }
}

export function animate() {
    movementWithPathFinding(clock.getDelta());
}


export function cancelPathfindingMovement() {
    currentPathFinding = 0;
    inMovementWithPathfinding = false;
    path = null;
    playerPosition.set(camera.position.x, camera.position.y - Constants.PLAYER.HEIGHT, camera.position.z);
    groupID = pathfinder.getGroup(ZONE, targetPosition, true);
    const closestNode = pathfinder.getClosestNode(playerPosition, ZONE, groupID, true);

    // helper.setPlayerPosition(playerPosition.copy(targetPosition));
    // if (closestNode) helper.setNodePosition(closestNode.centroid);

    var data = {
        visible: false,
        pos: new THREE.Vector3(0, 0, 0),
        lookAlt: new THREE.Vector3(0, 0, 0)
    }
    emit(Constants.EVENTS_NAME.POINTER_POSITION, data);
}

export function inMovement() {
    return inMovementWithPathfinding;
}