<div class="modal-selfie popup">
  <div class="container-modal-selfie">
    <div class="modal-layer-selfie"></div>
    <div class="modal-content-selfie">
      <!-- <button type="button" class="close-modal-selfie" (click)="onClose()"></button> -->
      <h2>{{ title }}</h2>
      <div class="component-selfie">
        <div class="row-selfie">
          <div class="row-selfie-button" style="padding-top: unset"></div>
          @if (isCameraAvailable) {
            <div class="row-media-selfie">
              <div
                [ngStyle]="{ display: showPhotoURL ? '' : 'none' }"
                [ngClass]="{ active: snapCameraStatus != SelfieCameraStatus.TAKE_PHOTO }"
              >
                <img
                  [src]="photoUrlTemp"
                  [ngClass]="{
                    'flash-effect': snapCameraStatus == SelfieCameraStatus.TAKE_AGAIN,
                    'no-rotate': isFile
                  }"
                />
              </div>
              <div
                [ngStyle]="{ display: showPhotoURL ? 'none' : '' }"
                id="user-selfie"
                class="camera-user-selfie"
              >
                @if (isCameraEnabled) {
                  <video id="user-selfie-video"></video>
                  <div>
                    <img
                      src="assets/images/icon-camera-loading.gif"
                      class="loading-camera"
                      alt="Linkroom"
                    />
                  </div>
                }
                @if (isTakingPhoto) {
                  <div class="countphoto">
                    <ul>
                      <li [ngClass]="{ active: snapStatus == '3' }">3</li>
                      <li [ngClass]="{ active: snapStatus == '2' }">2</li>
                      <li [ngClass]="{ active: snapStatus == '1' }">1</li>
                      <li><i></i></li>
                    </ul>
                  </div>
                }
              </div>
            </div>
          }

          @if (isCameraAvailable) {
            <div class="row-selfie-button">
              @if (!isTakingPhoto && photoUrlTemp != DefaultImage.BLANK) {
                <button id="uploadSelfie" (click)="uploadSelfie()">
                  {{ 'common.continue' | translate }}
                </button>
              }
              @if (!isTakingPhoto) {
                <input
                  type="file"
                  #fileUploader
                  (change)="setProfilePic($event)"
                  style="display: none"
                  accept="image/*"
                />
                @if (isCameraEnabled) {
                  <button id="snapSelfie" [disabled]="!cameraReady" (click)="startCountdown()">
                    {{ cameraReady ? snapStatus : ('common.loading' | translate) + '...' }}
                  </button>
                }
                <button (click)="displayFileUpload()">
                  {{ 'room.selfie.upload-photo' | translate }}
                </button>
                @if (canClose) {
                  <button (click)="onSkip()" class="btn-selfie-close">{{ cancelText }}</button>
                }
              }
            </div>
          } @else {
            <div class="row-media-selfie">
              <h2>{{ 'common.no-camera-detected' | translate }}</h2>
            </div>

            <div class="row-selfie-button">
              <button (click)="onSkip()" class="btn-selfie-close">{{ cancelText }}y</button>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>
