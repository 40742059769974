<div
  id="module-user-setting"
  class="popup"
  @fadeInOut
  [ngClass]="{ fixedPosition: !isInRoom }"
  [ngStyle]="{ 'pointer-events': enableControls ? 'all' : 'none' }"
>
  <div class="row-user-setting">
    <div class="wrapper-user-setting">
      <div class="row-content-user-setting">
        <button
          class="button-close-user-setting"
          id="closeUserSettings"
          (click)="onClose()"
        ></button>
        <div class="row-title-user-setting">
          <h2>{{ 'user-settings.title' | translate }}</h2>
          <div class="mode-user-setting">
            <span>{{ 'user-settings.dark-mode' | translate }}</span>
            <div
              class="action-user-setting"
              [class.active]="isDarkModeEnabled"
              (click)="toggleDarkMode($event)"
            >
              <div></div>
            </div>
          </div>
        </div>
        <div class="row-fiel-user-setting">
          <input
            type="text"
            [placeholder]="'user-settings.first-name' | translate"
            [(ngModel)]="firstName"
          />
          <input
            type="text"
            [placeholder]="'user-settings.last-name' | translate"
            [(ngModel)]="lastName"
          />
          <input
            type="text"
            [placeholder]="'user-settings.nickname' | translate"
            [(ngModel)]="nickName"
          />
        </div>
        <div class="user-setting-linkpad">
          <div class="column-setting-linkpad">
            <div class="avatar-setting-linkpad">
              @if (photoUrl.length == 0) {
                <button
                  type="button"
                  id="selectImageButton"
                  class="selectimage-setting-linkpad"
                  (click)="toggleSelfie()"
                ></button>
              } @else {
                <button
                  type="button"
                  class="removeimage-setting-linkpad"
                  (click)="removeUserPicture()"
                ></button>
              }

              <div
                id="self-settings-camera"
                [style.display]="photoUrl.length == 0 ? '' : 'none'"
              ></div>

              <div
                class="avatarletter-setting-linkpad"
                [ngStyle]="{ background: photoUrl.length > 0 ? '' : '#0091FF' }"
              >
                @if (photoUrl.length > 0) {
                  <img [src]="photoUrl" />
                } @else {
                  <span>{{ getInitials() }}</span>
                }
              </div>

              <div
                class="avatarletter-setting-linkpad"
                style="background: ''"
                *ngIf="photoUrl.length > 0"
              >
                <img [src]="photoUrl" style="object-fit: fill" />
              </div>

              <span class="username-setting-linkpad">[{{ getLinkpadName() }}]</span>
            </div>

            <h3>{{ 'user-settings.front-linkpad' | translate }}</h3>
            <p [innerHTML]="'user-settings.save-on-exit' | translate"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

@if (showSelfie) {
  <app-selfie
    [title]="selfieTitle"
    [cancelText]="cancelSelfieText"
    [isCameraEnabled]="isCameraEnabled"
    (close)="toggleSelfie()"
    (skip)="toggleSelfie()"
    (continue)="uploadSelfie($event)"
  ></app-selfie>
}

@if (showLoadingModal && loadingModalOptions) {
  <app-loading-modal
    [title]="loadingModalOptions.title"
    [message]="loadingModalOptions.message"
    [loading]="loadingModalOptions.loading"
  ></app-loading-modal>
}
