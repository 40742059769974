import * as THREE from 'three';
import CameraControls from 'camera-controls';
import OCTREE from './octreeController.js';
import * as TRANSFORM_CONTROL from './transformController.js';

const developerMode = false;

let scene;
let camera;
let sceneCollider;
let orbitControls;
let clock;

let inOrbitMode = false;
let leftSideDetected = false;
let rightSideDetected = false;

let orbitRaycaster = new THREE.Raycaster();
let rayHelpers = [];
let leftlimit;
let rightlimit;
let smoothDrag = 0.3;

let currentTargetMesh;

const distanceToCollision = 2.5;
const raySpreadAngle = 0.05;  // Narrow the angle of the spread

function init(_scene, _camera, _inputElement, _sceneCollider) {
    scene = _scene;
    camera = _camera;
    sceneCollider = _sceneCollider;
    clock = new THREE.Clock();

    orbitControls = new CameraControls(camera, _inputElement);
    orbitControls.minDistance = 1;
    orbitControls.maxDistance = 5; 
    orbitControls.dollyToCursor = false;
    orbitControls.enabled = false;
    orbitControls.dragToRotate = false;
    orbitControls.mouseButtons.right = CameraControls.ACTION.NONE;
    orbitControls.azimuthRotateSpeed = OCTREE.isMobileDevice() == true ? -smoothDrag : smoothDrag;
    orbitControls.polarRotateSpeed = OCTREE.isMobileDevice() == true ? -smoothDrag : smoothDrag;
    orbitControls.saveState();
}

// Function to create and visualize multiple rays
function createRayHelpers(numRays) {
    clearRaycastHelpers();

    for (let i = 0; i < numRays; i++) {
        const rayHelper = new THREE.ArrowHelper(
            new THREE.Vector3(0, 0, 0), 
            new THREE.Vector3(0, 0, 0), 
            5,
            0x5f16d7
        );
        scene.add(rayHelper);
        rayHelpers.push(rayHelper);
    }
}

function clearRaycastHelpers() {
    if (rayHelpers.length > 0) {
        rayHelpers.forEach(helper => scene.remove(helper));
        rayHelpers = [];
    }
}

function orbitToPosition(position) {
    if(inOrbitMode){
        return;
    }

    OCTREE.enableControls(false);

    if(developerMode) createRayHelpers(5);    
    orbitControls.reset();
    
    OCTREE.enableCameraRotation(false);
    orbitControls.enabled = true;
    orbitControls.minAzimuthAngle = -Infinity;
    orbitControls.maxAzimuthAngle = Infinity;
    
    const offsetFactor = 2;
    const cameraPosition = camera.position.clone(); 
    const direction = new THREE.Vector3().subVectors(position, cameraPosition).normalize();
    const distance = cameraPosition.distanceTo(position);
    const result = distance -  offsetFactor;
    
    const newCameraPosition = new THREE.Vector3().copy(cameraPosition).add(
        direction.multiplyScalar(result)
    );    

    orbitControls.moveTo(
        newCameraPosition.x,
        // camera.position.y + 1,
        camera.position.y,
        newCameraPosition.z,
        true
    );

    orbitControls.setTarget(
        position.x,
        position.y,
        position.z
    );

    orbitControls.update(0);
    orbitControls.minPolarAngle = THREE.MathUtils.degToRad(5);
    // orbitControls.minPolarAngle = THREE.MathUtils.degToRad(15);
    orbitControls.maxPolarAngle = THREE.MathUtils.degToRad(70);
    inOrbitMode = true;    
}

function orbitToSceneObject(uuid) {
    // const uuid = data.data.uuid;
    console.log(uuid);
    
    if(inOrbitMode){
        return;
    }
    // camera.fov = 75;
    const targetMesh = TRANSFORM_CONTROL.getSceneObject(uuid);
    currentTargetMesh = targetMesh;

    // console.log(targetMesh);
    // console.log(camera.position.clone());
    
    OCTREE.enableControls(false);

    if(developerMode) createRayHelpers(5);    
    orbitControls.reset();
    
    OCTREE.enableCameraRotation(false);
    orbitControls.enabled = true;
    orbitControls.minAzimuthAngle = -Infinity;
    orbitControls.maxAzimuthAngle = Infinity;
    
    const offsetFactor = 2; //2
    const cameraPosition = camera.position.clone(); 
    const targetPosition = targetMesh.position.clone();

    const dimensions = OCTREE.getSizeObject(targetMesh);    
    const boundingBox = new THREE.Box3().setFromObject(targetMesh);
    const size = new THREE.Vector3();
    boundingBox.getSize(size);
    const largestSide = Math.max(size.x, size.y, size.z);
    // console.log('largestSide', largestSide);
    
    const direction = new THREE.Vector3().subVectors(targetPosition, cameraPosition).normalize();
    const distance = cameraPosition.distanceTo(targetPosition);
    const result = distance - largestSide * offsetFactor;
    
    const newCameraPosition = new THREE.Vector3().copy(cameraPosition).add(
        direction.multiplyScalar(result)
    );    

    // console.log('newCameraPosition', newCameraPosition);    

    orbitControls.moveTo(
        newCameraPosition.x,
        // newCameraPosition.y,
        camera.position.y + dimensions.height,
        newCameraPosition.z,
        true
    );

    // console.log(targetMesh.position.y, dimensions.center.y);
    
    orbitControls.setTarget(
        targetMesh.position.x,
        // targetMesh.position.y,
        dimensions.center.y,
        targetMesh.position.z
    );

    orbitControls.update(0);
    orbitControls.minPolarAngle = THREE.MathUtils.degToRad(5); //15
    orbitControls.maxPolarAngle = THREE.MathUtils.degToRad(95);
    orbitControls.enableZoom = false;
    inOrbitMode = true;    

    // console.log(camera.position.clone());
    // console.log(camera.fov);  
}

function spaceTooNarrow(point) {
    // console.log('bring closer');
    orbitControls.moveTo(
        point.x,
        camera.position.y,
        point.z
    );
    orbitControls.update(0);
}

function switchToFirstPersonMode() {
    if(!inOrbitMode){
        // console.log('already in first person mode');
        return;
    }
    // camera.fov = 45;

    OCTREE.enableControls(true);

    inOrbitMode = false;
    if(developerMode) clearRaycastHelpers();
    orbitControls.enableZoom = true;
    orbitControls.enabled = false;
    OCTREE.enableCameraRotation(true);
 
    leftSideDetected = false;
    rightSideDetected = false;
}

function updateRayHelpersWithPrediction() {
    const targetPosition = orbitControls.getTarget(new THREE.Vector3());
    const cameraPosition = orbitControls.getPosition(new THREE.Vector3());

    const cameraDirection = new THREE.Vector3().subVectors(cameraPosition, targetPosition).normalize();

    // For ray visualization for debugging
    const spreadAngle = 0.05;
    const directions = [
        cameraDirection.clone(), // Main ray
        cameraDirection.clone().applyAxisAngle(new THREE.Vector3(0, 1, 0), spreadAngle),  // Right (Y-axis rotation)
        cameraDirection.clone().applyAxisAngle(new THREE.Vector3(0, 1, 0), -spreadAngle),// Left (Y-axis rotation)
        cameraDirection.clone().applyAxisAngle(new THREE.Vector3(1, 0, 0), spreadAngle),  // Up (X-axis rotation)
        cameraDirection.clone().applyAxisAngle(new THREE.Vector3(1, 0, 0), -spreadAngle)  // Down (X-axis rotation)
    ];

    // Perform raycasting and update ray helpers
    const azimuthBuffer = 0.05;
    for (let i = 0; i < directions.length; i++) {
        let rayHelper;
        if(developerMode) {
            rayHelper = rayHelpers[i]; 
            rayHelper.position.copy(targetPosition); 
            rayHelper.setDirection(directions[i]); 
        }
        orbitRaycaster.set(targetPosition, directions[i]); 

        const intersects = orbitRaycaster.intersectObject(sceneCollider, true);

        if (intersects.length > 0 && intersects[0].distance < distanceToCollision) {
            const azimuthAngle = orbitControls.azimuthAngle;
            // if (leftSideDetected && rightSideDetected) {
            //     if (leftlimit - rightlimit <= 0.1) {
            //         console.log('space too narrow', intersects[0].point);        
            //         // Try bringing cam closer      
            //         spaceTooNarrow(intersects[0].point);     
            //         leftSideDetected = false;
            //         rightSideDetected = false;
            //     }    
            // }

            if (i === 2) {
                if (!leftSideDetected) {
                    leftSideDetected = true;
                    orbitControls.minAzimuthAngle = azimuthAngle;
                    // console.log("Left wall detected, limit set at:", azimuthAngle);
                    leftlimit = azimuthAngle;
                    orbitControls.azimuthAngle = Math.max(orbitControls.azimuthAngle, azimuthAngle + azimuthBuffer); // Add buffer
                }else{
                    // console.log('');
                    
                }
            } else if (i === 1) {
                if (!rightSideDetected) {
                    rightSideDetected = true;
                    orbitControls.maxAzimuthAngle = azimuthAngle;
                    // console.log("Right wall detected, limit set at:", azimuthAngle);
                    rightlimit = azimuthAngle;
                    orbitControls.azimuthAngle = Math.min(orbitControls.azimuthAngle, azimuthAngle - azimuthBuffer); // Add buffer
                }
            }
        }

        if(developerMode) {
            const rayLength = intersects.length > 0 ? intersects[0].distance : 5;
            rayHelper.setLength(rayLength);
        }

    }

    const azimuthRange = Math.abs(orbitControls.maxAzimuthAngle - orbitControls.minAzimuthAngle);
    const tightSpaceThreshold = 0.1; // Adjust this value as needed (in radians)
    // console.log('azimuthRange', azimuthRange);
    
    if (azimuthRange < tightSpaceThreshold) {
        // console.log("Camera is in a narrow space!", azimuthRange);
        leftSideDetected = false;
        rightSideDetected = false;
        orbitControls.minAzimuthAngle = -Infinity;
        orbitControls.maxAzimuthAngle = Infinity;
    }

    orbitControls.update(0); 
}

function getInOrbitMode() {
    return inOrbitMode;
}

function animate() {   
    const deltaTime = Math.min(0.05, clock.getDelta());

    if (inOrbitMode) {
        orbitControls.update(deltaTime);
        // updateRayHelpersWithPrediction();
    }
}

const OrbitModeController = {
    init,
    orbitToPosition,
    orbitToSceneObject,
    switchToFirstPersonMode,
    getInOrbitMode,
    animate
}

export default OrbitModeController;