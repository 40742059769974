export const ACTION_CODE= {
    LOAD_SCENE: 1,
    NAVIGATE_TO_POSITION: 2,
    NAVIGATE_TO_OBJECT: 3,
    ORBIT_OBJECT: 4,
    ENABLE_SINGLE_USER_EXPERIENCE: 5,
    DISABLE_SINGLE_USER_EXPERIENCE: 6,
    REMOVE_FROM_COMMUNICATIONS_CHANNEL: 7,
    REMOVE_FROM_NETWORKING_ROOM: 8,
    SHOW_ALERT: 9,
    LOOK_AT_POSITION: 10,
    SHOW_MODAL: 11,
    ADD_METADATA: 12,
    ROTATE_360: 13,
    

    GATHER : "gather",
    ORBIT : "orbit",
    PATTERN : "pattern",
    LOOK : "look",
    FREEZE : "freeze",
    UNFREEZE : "unfreeze",
    FOLLOW : "follow",
    UNFOLLOW : "unfollow",
    MUTE : "mute",
    UNMUTE : "unmute",
}