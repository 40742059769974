import { SphereGeometry, MeshBasicMaterial, Mesh, Box3, Vector3 } from "three";
import { Capsule } from 'three/addons/math/Capsule.js';
import { getSceneCollier } from "../../../navigation/src/octreeController";
import { checkCollisions } from "../../../navigation/src/index";

let pointsNumber = 0;
let pointersLimit = 0;
let pointerGeo = null;
let pointerMaterialGreen = null;
let pointerMaterialGRed = null;
let originalPointer = null;
let originalPointerCollider = null;
let pointer = [];
let pointerCollider = [];
//let sceneCollider = null;
let collision = false;

export function initPointer(numberpoints, scene) {
    pointerGeo = new SphereGeometry(0.1, 16, 1);
    pointerMaterialGreen = new MeshBasicMaterial({
        color: 0x83E764,
        transparent: true,
        opacity: 0.8
    });
    pointerMaterialGRed = new MeshBasicMaterial({
        color: 0xFF0000,
        transparent: true,
        opacity: 0.8
    });
    originalPointer = new Mesh(pointerGeo, pointerMaterialGreen);
    pointsNumber = numberpoints;
    for (let i = 0; i < pointsNumber; i++) {
        pointer[i] = originalPointer.clone();
        pointer[i].scale.set(1, 0.01, 1);
        pointer[i].visible = false;
    }
    pointer.forEach((p) => {
        scene.add(p);
    });

}
export function setPointerLimit(limit) {
    pointersLimit = limit;
    //sceneCollider = getSceneCollier();
}

export async function setPointerVisibility(visible) {
    return new Promise((resolve) => {
        
        if (!visible) {
            pointer.forEach(p => p.visible = false);
        } else {
            pointer.slice(0, pointersLimit).forEach(p => p.visible = true);
        }
        resolve();
    });
}

export function updateActionPointer(msg) {
    setPointerLimit(msg.data.length);
    setPointerVisibility(true);
    let isCollision = false;
    for (let i = 0; i < pointersLimit; i++) {
        pointer[i].position.set(msg.data[i].position.x, msg.data[i].position.y + 0.01, msg.data[i].position.z);
        if(checkCollisions(pointer[i])){
            isCollision = true;
        }
    } 
    changeColor(isCollision);
}

export function changeColor(value) {
    if (value && !collision) {
        for (let i = 0; i < pointersLimit; i++) {
            pointer[i].material = pointerMaterialGRed;
        }
        collision = true;
    }
    else if (!value && collision) {
        for (let i = 0; i < pointersLimit; i++) {
            pointer[i].material = pointerMaterialGreen;
        }
        collision = false;
    }
}
export function getIsCollision() {
    return collision;
}
//export function updatePointer()



