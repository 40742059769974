import * as THREE from 'three';
import * as PATHFINDING from './pathfindingController.js';
import OCTREE from './octreeController.js';
import ORBIT from './orbitModeController.js';
import { Constants } from './constants.js';
import * as TRANSFORM_CONTROL from './transformController.js';
import * as PATHFINDING_WITH_NAVMESH from './pathFindingNavMeshController.js';
import { getIsCollision } from '../../viewer/src/controllers/actionPointerController.js';
var camera = null;
var inputElement = null;
var scene;
var sceneObjectsGroup;
var navMeshLayer = 6;
var collisionLayer = 5;
let sceneObjectsLayer = -1;

let raycaster;
let raycasterSceneObjects;
let raycasterNavMesh;
let raycasterCreationSurface;
// let clock;
let interactables = {};
let sceneCollider;
let navMeshGroup;
let creationSurfaceGroup;
let creationSurfaceLayer;
let creationSurface;
let navMesh;

const EPS = 1e-5;

var mouseDown = new THREE.Vector2(0, 0);
var mouseMove = new THREE.Vector2(0, 0);

var currentState = Constants.STATE.MOVEMENT;

var useNewPathfinding = true;

var CURRENT_PATHFINDING;

var distanceToMove = 0.2;
var previousCameraPos = null;
var degreeToRotate = 10 * Math.PI / 180;
var previousCameraRot;

var events = {};
var isMobile = false;

var enableInputs = true;

var isFreelook = false;
var isActionActive = false;
var currentAction = null;
var selectedUsers = null;

let previewVisible = false;

let closeToCamera = false;
let closeToCameraDistance = false;
let sceneObjectCloseToCamera;
let positionSceneObjectclose;
const geometry = new THREE.PlaneGeometry(0.3, 0.3);
//const textureLoader = new THREE.TextureLoader();
//const texture = textureLoader.load('https://upload.wikimedia.org/wikipedia/commons/3/39/Pointing_hand_cursor_vector.svg');
//const material = new THREE.MeshBasicMaterial({ map: texture}); 
const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });

let planeHand;

let isPlayerMoving = false;

export var subscribe = (_event, listener) => {
    if (!events[_event]) {
        events[_event] = [];
    }
    events[_event].push(listener);
}

export var unsubscribe = (_event, listener) => {
    if (events[_event]) {
        events[_event] = events[_event].filter((existingListener) => {
            return existingListener !== listener;
        });
    }
}

var emit = (_event, data) => {
    if (events[_event]) {
        events[_event].forEach((listener) => {
            listener(data);
        });
    }
}

export function init(options) {
    camera = options.camera;
    isMobile = options.isMobile ? options.isMobile : true;
    // clock = options.clock;
    previousCameraPos = camera.position.clone();
    previousCameraRot = camera.quaternion.clone();
    // camera.position.set(0, 0, EPS);
    inputElement = options.inputElement;

    OCTREE.init(camera, inputElement, isFreelook);
}

let useNavMesh = false;
let useCreationSurface = false;
export async function initPhysics(options, enableLocalUpdate = false) {

    scene = options.scene;
    sceneObjectsGroup = options.sceneObjectsGroup;
    navMeshLayer = options.navMeshLayer;
    collisionLayer = options.collisionLayer;
    sceneObjectsLayer = options.sceneObjectsLayer;
    sceneCollider = options.collider;
    navMeshGroup = options.navMeshGroup;
    creationSurfaceGroup = options.creationSurfaceGroup;
    creationSurfaceLayer = options.creationSurfaceLayer;

    if (options.useNewPathfinding != undefined || options.useNewPathfinding != null)
        useNewPathfinding = options.useNewPathfinding;

    await OCTREE.initPhysics(sceneCollider);

    ORBIT.init(scene, camera, inputElement, sceneCollider);

    TRANSFORM_CONTROL.initTransform(scene, camera, inputElement);


    if (navMeshGroup.children.length > 0) {
        CURRENT_PATHFINDING = PATHFINDING_WITH_NAVMESH;
        CURRENT_PATHFINDING.init(camera);
        navMesh = CURRENT_PATHFINDING.initPath(navMeshGroup, navMeshLayer);
        useNavMesh = true;
    } else {
        CURRENT_PATHFINDING = PATHFINDING;
        CURRENT_PATHFINDING.init(camera);
        navMesh = CURRENT_PATHFINDING.initPath(sceneCollider, collisionLayer);
        useNavMesh = false;
    }


    if (creationSurfaceGroup.children.length > 0) {
        creationSurfaceGroup.traverse(child => {
            if (child.isMesh) {
                child.material.wireframe = true;
                child.material.color = new THREE.Color(0x00ff00);
                creationSurface = child;
            }
        })
        useCreationSurface = true;
    } else {
        sceneCollider.traverse(child => {
            if (child.isMesh) {
                creationSurface = child;
            }
        })
        useCreationSurface = false;
    }

    raycaster = new THREE.Raycaster();
    raycaster.layers.set(collisionLayer);

    raycasterSceneObjects = new THREE.Raycaster();
    raycasterSceneObjects.layers.set(sceneObjectsLayer);

    raycasterNavMesh = new THREE.Raycaster();
    raycasterNavMesh.layers.set(useNavMesh ? navMeshLayer : collisionLayer);

    raycasterCreationSurface = new THREE.Raycaster();
    raycasterCreationSurface.layers.set(useCreationSurface ? creationSurfaceLayer : collisionLayer);


    registerEvents();

    planeHand = new THREE.Mesh(geometry, material);
    scene.add(planeHand);
    planeHand.visible = false;

    if (enableLocalUpdate == true) {
        localUpdate();
    }
}

let rejectRaycast = null;

function registerEvents() {

    var _key;
    try {
        _key = window ? document : inputElement;
    } catch (error) {
        _key = inputElement;
    }

    _key.addEventListener('keydown', (event) => {
        registerKeyCodeOctree(event.code, true);
    });

    _key.addEventListener('keyup', (event) => {
        registerKeyCodeOctree(event.code, false);

        if (event.code === Constants.PLAYER_INPUT.ESCAPE) {
            TRANSFORM_CONTROL.hideTransformControl();
            emit(Constants.EVENTS_NAME.SELECT_SCENEOBJECT, undefined);
        }

        if (event.code === Constants.PLAYER_INPUT.ESCAPE && currentState === Constants.STATE.NEW_OBJECT) {
            if (rejectRaycast)
                rejectRaycast('Pressed Scape');
        }

        //for PC
        if (event.code == Constants.PLAYER_INPUT.FREE_LOOK) {
            setFreeLook();
        }

    });

    //for mobile
    // inputElement.addEventListener('dblclick', (event) => {
    //     if (OCTREE.isMobileDevice()) {
    //         console.log('dblclick');            
    //         setFreeLook();
    //     }
    // })

    let lastTapTime = 0;

    inputElement.addEventListener('touchstart', (event) => {
        const currentTime = Date.now();
        const tapInterval = currentTime - lastTapTime;

        if (tapInterval < 300 && tapInterval > 0) {
            if (OCTREE.isMobileDevice()) {
                // console.log('touchstart double click');         
                setFreeLook();
            }
        }
        lastTapTime = currentTime;
    })

    // inputElement.addEventListener("touchstart", (event) => { }, { passive: true });
    // inputElement.addEventListener("touchmove", (event) => { }, { passive: true });
    // inputElement.addEventListener("wheel", (event) => { }, { passive: true });

    inputElement.addEventListener('pointerdown', (event) => {
        var { mousePosX, mousePosY } = getMousePosition(event);
        mouseDown = new THREE.Vector2(mousePosX, mousePosY);

        const inOrbitMode = ORBIT.getInOrbitMode();
        if (inOrbitMode) return;
        CURRENT_PATHFINDING.updatePointer(mouseDown.x, mouseDown.y);
    }, false);

    inputElement.addEventListener('mousemove', (event) => {
        var { mousePosX, mousePosY } = getMousePosition(event);
        mouseMove = new THREE.Vector2(mousePosX, mousePosY);
        //Get raycast point for sceneObjectIndicator
        //Preview
        if (currentState === Constants.STATE.NEW_OBJECT) {
            raycasterCreationSurface.setFromCamera(mouseMove, camera);
            let intersectionPreviewIndicator = raycasterCreationSurface.intersectObject(creationSurface);
            if (intersectionPreviewIndicator.length > 0) {
                emit(Constants.EVENTS_NAME.RAYCAST_PREVIEW_INDICATOR, intersectionPreviewIndicator[0]);
                emit(Constants.EVENTS_NAME.RAYCAST_CREATE_OBJECT_CURSOR, intersectionPreviewIndicator[0]);
                previewVisible = true;
            } else {
                if (previewVisible) {
                    emit(Constants.EVENTS_NAME.RAYCAST_HIDE_PREVIEW);
                    previewVisible = false;
                }
            }
        }

        //Hover sceneObjects
        if (currentState === Constants.STATE.MOVEMENT || currentState === Constants.STATE.HOST_ACTION) {
            raycasterNavMesh.setFromCamera(mouseMove, camera);
            raycasterSceneObjects.setFromCamera(mouseMove, camera);

            let intersectionObjectCursor = raycasterSceneObjects.intersectObjects(sceneObjectsGroup.children);
            if (intersectionObjectCursor.length > 0) {
                emit(Constants.EVENTS_NAME.RAYCAST_HOVER_OBJECT_CURSOR);
            } else {
                //Pointer pathfinding
                let intersectionCursor = raycasterNavMesh.intersectObject(navMesh);
                if (intersectionCursor.length > 0) {
                    if (!isPlayerMoving) {
                        emit(Constants.EVENTS_NAME.RAYCAST_DEFAULT_CURSOR, intersectionCursor[0]);
                    } else {
                        emit(Constants.EVENTS_NAME.RAYCAST_HIDE_DEFAULT_CURSOR);
                    }
                }
            }
        }

        //Actions
        if (currentState == Constants.STATE.HOST_ACTION && currentAction !== 'freeze'
            && currentAction !== 'unFreeze' && selectedUsers !== null && selectedUsers.length > 0) {
            raycasterNavMesh.setFromCamera(mouseMove, camera);
            let intersectionIndicator = raycasterNavMesh.intersectObject(navMesh);
            if (intersectionIndicator.length > 0) {
                emit(Constants.EVENTS_NAME.ACTION_POSITION_INDICATOR, {
                    position: intersectionIndicator[0].point,
                    action: currentAction,
                    users: selectedUsers
                });
            }
        }

    }, false);

    inputElement.addEventListener('pointerup', async (event) => {
        event.preventDefault();

        if (isSwipe(event)) return;

        const inOrbitMode = ORBIT.getInOrbitMode();
        if (inOrbitMode) return;

        raycasterNavMesh.setFromCamera(mouseMove, camera);
        raycasterSceneObjects.setFromCamera(mouseMove, camera);
        raycasterCreationSurface.setFromCamera(mouseMove, camera);

        switch (currentState) {
            case Constants.STATE.MOVEMENT:
                const intersectionSceneObjects = raycasterSceneObjects.intersectObjects(sceneObjectsGroup.children);
                if (intersectionSceneObjects.length > 0) {
                    let obj = intersectionSceneObjects[0].object;
                    if (obj.userData.type == "SCENE_OBJECT") {
                        TRANSFORM_CONTROL.modifyObject(obj.userData.uuid);
                        emit(Constants.EVENTS_NAME.SELECT_SCENEOBJECT, TRANSFORM_CONTROL.getCurrentSceneObject());
                        changeState(Constants.STATE.MODIFY_OBJECT);
                    }
                } else {
                    TRANSFORM_CONTROL.hideTransformControl();
                    emit(Constants.EVENTS_NAME.SELECT_SCENEOBJECT, undefined);
                    var { mousePosX, mousePosY } = getMousePosition(event);
                    if (isMobile)
                        CURRENT_PATHFINDING.findAPath(mousePosX, mousePosY);
                }
                break;
            case Constants.STATE.NEW_OBJECT:
                const intersection = raycasterCreationSurface.intersectObject(creationSurface);
                if (intersection.length > 0) {
                    emit(Constants.EVENTS_NAME.RAYCAST_POINT, intersection[0]);
                    changeState(Constants.STATE.MOVEMENT);
                } else {
                    // console.log('not intersecting with room collider');                    
                }
                // changeState(Constants.STATE.MOVEMENT);
                break;
            case Constants.STATE.MODIFY_OBJECT:
                const intersectionSceneObjects2 = raycasterSceneObjects.intersectObjects(sceneObjectsGroup.children);
                if (intersectionSceneObjects2.length > 0) {
                    let obj = intersectionSceneObjects2[0].object;
                    if (obj.userData.type == "SCENE_OBJECT") {
                        TRANSFORM_CONTROL.modifyObject(obj.userData.uuid);
                        emit(Constants.EVENTS_NAME.SELECT_SCENEOBJECT, TRANSFORM_CONTROL.getCurrentSceneObject());
                        changeState(Constants.STATE.MODIFY_OBJECT);
                    }
                } else {
                    TRANSFORM_CONTROL.hideTransformControl();
                    emit(Constants.EVENTS_NAME.SELECT_SCENEOBJECT, undefined);
                    changeState(Constants.STATE.MOVEMENT);
                    var { mousePosX, mousePosY } = getMousePosition(event);
                    if (isMobile)
                        CURRENT_PATHFINDING.findAPath(mousePosX, mousePosY);
                }
                break;
            case Constants.STATE.HOST_ACTION:
                let intersectionfloor;
                let data;
                if (getIsCollision()) break;
                if (currentAction == 'gather' || currentAction == 'pattern' || currentAction == 'look' || currentAction == 'orbit') {
                    intersectionfloor = raycasterNavMesh.intersectObject(navMesh);
                    data = { action: currentAction, users: selectedUsers, position: intersectionfloor.length > 0 ? intersectionfloor[0].point : null };
                }
                else {
                    data = { action: currentAction, users: selectedUsers };
                }
                emit(Constants.EVENTS_NAME.SEND_HOST_ACTION, data);
                changeState(Constants.STATE.MOVEMENT);
                break;
                return;

        }
    }, false);

    CURRENT_PATHFINDING.subscribe(Constants.EVENTS_NAME.LOOK_AT_TARGET, lookAtTarget);
    CURRENT_PATHFINDING.subscribe(Constants.EVENTS_NAME.MOVE_PLAYER_TO_POSITION, movePlayerToPosition);
    CURRENT_PATHFINDING.subscribe(Constants.EVENTS_NAME.POINTER_POSITION, (data) => {
        emit(Constants.EVENTS_NAME.POINTER_POSITION, data);
    })

    TRANSFORM_CONTROL.subscribe(Constants.EVENTS_NAME.MODIFY_OBJECT, enableCameraRotation);
    TRANSFORM_CONTROL.subscribe(Constants.EVENTS_NAME.APPLY_OBJECT_MODIFICATION, applyObjectModification);
    TRANSFORM_CONTROL.subscribe(Constants.EVENTS_NAME.CHANGE_SCENEOBJECT, (e) => emit(Constants.EVENTS_NAME.CHANGE_SCENEOBJECT, e));
}


function setFreeLook() {
    isFreelook = !isFreelook;
    OCTREE.enableFreelook(isFreelook);
}


export function isSwipe(event) {

    var { mousePosX, mousePosY } = getMousePosition(event);
    if (Math.abs(mouseDown.x - mousePosX) > 0.005 || Math.abs(mouseDown.y - mousePosY) > 0.005)
        return true;
    else
        return false;
}

export function registerInteractable(obj) {
    obj.object.userData = { uuid: obj.uuid, type: "INTERACTABLE" };
    obj.object.traverse(child => {
        if (child.isMesh) {
            child.layers.enable(sceneLayer);
            child.userData.type = "INTERACTABLE";
            child.userData.uuid = obj.uuid;
        }
    })
    addInteractableCollision(obj.object, obj.uuid, true);
}

export function registerSceneObject(obj) {
    obj.sceneObject.userData = { uuid: obj.uuid, type: "SCENE_OBJECT", group: obj.group, assetId: obj.assetId };
    obj.sceneObject.traverse(child => {
        if (child.isMesh) {
            child.layers.enable(sceneObjectsLayer);
            child.userData.type = "SCENE_OBJECT";
            child.userData.uuid = obj.uuid;
            child.userData.assetId = obj.assetId;
        }
    });
    addCollisionObject(obj.sceneObject, true);
}

export function getPlayerHeight() {
    return new Promise((resolve, reject) => {
        resolve({ height: Constants.PLAYER.HEIGHT });
    })
}

export function clampRotateCamera(value) {
    OCTREE.clampRotateCamera(value);
}

export function setMode(mode = Constants.MODE.translate) {
    TRANSFORM_CONTROL.setMode(mode);
}


function getMousePosition(event) {

    let mousePosX = 0;
    let mousePosY = 0;

    if (inputElement) {
        mousePosX = (event.clientX / inputElement.clientWidth) * 2 - 1;
        mousePosY = - (event.clientY / inputElement.clientHeight) * 2 + 1;
    }
    return { mousePosX, mousePosY }
}

export function getCurrentState() {
    return currentState;
}

export function changeState(state) {
    currentState = state;
}

export function createRaycastForNewObject() {
    return new Promise((resolve, reject) => {
        rejectRaycast = reject;
        changeState(Constants.STATE.NEW_OBJECT);

        const eventRaycast = (intersection) => {
            resolve({
                point: [intersection.point.x, intersection.point.y, intersection.point.z],
                normal: [intersection.face.normal.x, intersection.face.normal.y, intersection.face.normal.z]
            })
            // resolve([point.x, point.y, point.z]);
            unsubscribe(Constants.EVENTS_NAME.RAYCAST_POINT, eventRaycast);
        }

        subscribe(Constants.EVENTS_NAME.RAYCAST_POINT, eventRaycast);
    });
}

export function lookAtTarget(targetPoint) {
    OCTREE.lookAtTarget(targetPoint);
}

export function moveToTarget(targetPoint, lookAtTarget) {
    CURRENT_PATHFINDING.moveTo(targetPoint, lookAtTarget);
}

export function lookAtTargetInmediatly(targetPoint) {
    OCTREE.lookAtTargetInmediatly(new THREE.Vector3().fromArray(targetPoint));
}

export function movePlayerToPosition(targetPosition) {
    OCTREE.movePlayerToPosition(targetPosition);
}

export function movePlayerInmediatlyTo(targetPoint) {
    OCTREE.movePlayerInmediatlyTo(new THREE.Vector3().fromArray(targetPoint));
}

export function setEnableTransformControls(msg) {
    return new Promise((resolve, reject) => {
        TRANSFORM_CONTROL.setEnableTransformControls(msg.data.enabled);
        resolve();
    });
}

export function registerKeyCodeOctree(event, value) {
    OCTREE.registerKeyCode(event, value);
    cancelPathfindingMovement(event);
    hidePointerWhenPlayerIsMoving(event, value);
}

export function addCollisionObject(sceneObject, createBoxCollision = true) {
    TRANSFORM_CONTROL.addSceneObject(sceneObject);
    // OCTREE.addCollisionObject(sceneObject, createBoxCollision);
}

export function addInteractableCollision(interactable, uuid, createBoxCollision = true) {
    interactables[uuid] = interactable;
    OCTREE.addCollisionObject(interactable, createBoxCollision);
}

export function removeCollisionObject(uuid) {
    var currentObject = TRANSFORM_CONTROL.getSceneObject(uuid);
    OCTREE.removeCollisionObject(currentObject);

    TRANSFORM_CONTROL.removeSceneObject(currentObject);
    TRANSFORM_CONTROL.hideTransformControl();

}

export function startFitToObject(object3d) {
    OCTREE.startFitToObject(object3d);
}

export function stopFitToObject() {
    OCTREE.stopFitToObject();
}

function cancelPathfindingMovement(event) {
    var inMovementWithPathfinding = CURRENT_PATHFINDING.inMovement();
    if (inMovementWithPathfinding == true) {
        switch (event) {
            case Constants.PLAYER_INPUT.MOVE_UP:
            case Constants.PLAYER_INPUT.ESCAPE:
            case Constants.PLAYER_INPUT.MOVE_UP_WITH_ARROW:
            case Constants.PLAYER_INPUT.MOVE_DOWN:
            case Constants.PLAYER_INPUT.MOVE_DOWN_WITH_ARROW:
            case Constants.PLAYER_INPUT.MOVE_LEFT:
            case Constants.PLAYER_INPUT.MOVE_LEFT_WITH_ARROW:
            case Constants.PLAYER_INPUT.MOVE_RIGHT:
            case Constants.PLAYER_INPUT.MOVE_RIGHT_WITH_ARROW:
            case Constants.PLAYER_INPUT.JUMP:
                CURRENT_PATHFINDING.cancelPathfindingMovement();
                break;
        }
    }
}

function hidePointerWhenPlayerIsMoving(event, value) {
    if (value) {
        switch (event) {
            case Constants.PLAYER_INPUT.MOVE_UP:
            case Constants.PLAYER_INPUT.ESCAPE:
            case Constants.PLAYER_INPUT.MOVE_UP_WITH_ARROW:
            case Constants.PLAYER_INPUT.MOVE_DOWN:
            case Constants.PLAYER_INPUT.MOVE_DOWN_WITH_ARROW:
            case Constants.PLAYER_INPUT.MOVE_LEFT:
            case Constants.PLAYER_INPUT.MOVE_LEFT_WITH_ARROW:
            case Constants.PLAYER_INPUT.MOVE_RIGHT:
            case Constants.PLAYER_INPUT.MOVE_RIGHT_WITH_ARROW:
            case Constants.PLAYER_INPUT.JUMP:
                // console.log('hide pointer');
                isPlayerMoving = true;
                break;
        }
    } else {
        isPlayerMoving = false;
        emit(Constants.EVENTS_NAME.RAYCAST_HIDE_DEFAULT_CURSOR);
    }
}

export function applyObjectModification(enable) {
    if (enable) {
        enableCameraRotation(enable);
        var currentObject = TRANSFORM_CONTROL.getCurrentSceneObject();
        OCTREE.modifyCollisionObject(currentObject);
        emit(Constants.EVENTS_NAME.UPDATE_SCENEOBJECT, currentObject);
    }
}

export function getCurrentSceneObject() {
    return TRANSFORM_CONTROL.getCurrentSceneObject();
}

export function updateCollisionObject(uuid) {
    var currentObject = TRANSFORM_CONTROL.getSceneObject(uuid);

    if (currentObject)
        OCTREE.modifyCollisionObject(currentObject);
}

export function enableCameraRotation(enable) {
    OCTREE.enableCameraRotation(enable);
}

export function enablePlayerMovement(enable) {
    enableInputs = enable;
    OCTREE.enableControls(enable);
    CURRENT_PATHFINDING.enableControls(enable);

}

export function enablePlayerMovementAndRotation(msg) {
    return new Promise((resolve, reject) => {
        enablePlayerMovement(msg.data.enabled);
        //enableCameraRotation(msg.data.enabled);
        resolve();
    });
}

export function changeCameraMode(msg) {
    return new Promise((resolve, reject) => {
        switch (msg.data.mode) {
            case 0:
                ORBIT.switchToFirstPersonMode();
                break;
            case 1:
                ORBIT.orbitToSceneObject(msg.data.uuid);
                break;
            default:
                break;
        }
        TRANSFORM_CONTROL.hideTransformControl();
        resolve();
    })
}

function localUpdate() {
    animate();
    requestAnimationFrame(localUpdate);
}

export function forceSendPositionAndRotation() {
    let pos = camera.position.clone();
    let quaternion = camera.quaternion.clone();

    emit(Constants.EVENTS_NAME.PLAYER_MOVEMENT, pos);
    emit(Constants.EVENTS_NAME.PLAYER_ROTATION, quaternion);

    previousCameraPos = pos;
    previousCameraRot = quaternion;
}

function showHandHelper() {
    if (raycaster) {
        closeToCamera = false;
        closeToCameraDistance = 1000;
        for (let i = 0; i < sceneObjectsGroup.children.length; i++) {
            let posCamera = camera.position.clone();
            let posSceneobject = sceneObjectsGroup.children[i].position.clone();
            posSceneobject.y = posCamera.y;

            const distancia = camera.position.distanceTo(posSceneobject);
            const directionToObject = new THREE.Vector3().subVectors(posSceneobject, camera.position).normalize();
            const directionCamera = new THREE.Vector3();
            camera.getWorldDirection(directionCamera);
            const angle = directionCamera.angleTo(directionToObject);
            const umbral = 0.6;
            const lookAt = angle < umbral;

            if ((distancia < 8) && (lookAt)) {
                closeToCamera = true;
                if (distancia < closeToCameraDistance) {
                    sceneObjectCloseToCamera = sceneObjectsGroup.children[i];
                    closeToCameraDistance = distancia;
                }
            }
        }

        if (closeToCamera) {
            planeHand.visible = true;
            let posCamera = camera.position.clone();
            let posSceneobject = sceneObjectCloseToCamera.position.clone();
            posSceneobject.y = posCamera.y;
            let direction = new THREE.Vector3().subVectors(posCamera, posSceneobject).normalize();
            direction.multiplyScalar(0.8);
            positionSceneObjectclose = new THREE.Vector3().addVectors(posSceneobject, direction);
            //console.log("positionSceneObjectclose",positionSceneObjectclose);   
            planeHand.position.copy(positionSceneObjectclose)
            planeHand.lookAt(posCamera);
        } else {
            planeHand.visible = false;
        }
    }
}

export function animate() {

    //showHandHelper();
    //console.log("animate",currentState);
    var inMovementWithPathfinding = CURRENT_PATHFINDING.inMovement();

    if (enableInputs == true) {
        if (inMovementWithPathfinding == true) {
            OCTREE.enableControls(false);
            //Octree.enableCollision(false);
        } else {
            OCTREE.enableControls(true);
            //Octree.enableCollision(true);
        }
    }

    var dis = previousCameraPos.distanceTo(camera.position);
    if (dis >= distanceToMove) {
        // var pos = new THREE.Vector3(camera.position.x, camera.position.y, camera.position.z);
        emit(Constants.EVENTS_NAME.PLAYER_MOVEMENT, camera.position.clone());
        previousCameraPos = camera.position.clone();
    }

    const cameraQuaternion = camera.quaternion.clone();

    if (cameraQuaternion.angleTo(previousCameraRot) > degreeToRotate) {
        emit(Constants.EVENTS_NAME.PLAYER_ROTATION, cameraQuaternion);
        previousCameraRot = camera.quaternion.clone();
    }
    CURRENT_PATHFINDING.animate();
    OCTREE.animate();
    ORBIT.animate();
}

export function hideTransformControl() {
    TRANSFORM_CONTROL.hideTransformControl();
}

export function getCameraPosition() {
    return camera.position.clone();
}

export function forceUnselectSceneObject() {
    return new Promise((resolve, reject) => {
        TRANSFORM_CONTROL.hideTransformControl();
        emit(Constants.EVENTS_NAME.SELECT_SCENEOBJECT, undefined);
        resolve();
    })
}

export function orbitToSceneObject(uuid) {
    return new Promise((resolve, reject) => {
        ORBIT.orbitToSceneObject(uuid);
        resolve();
    });
}

export function orbitToPosition(position) {
    return new Promise((resolve, reject) => {
        ORBIT.orbitToPosition(position);
        resolve();
    });
}

export function exitOrbitMode() {
    return new Promise((resolve, reject) => {
        ORBIT.switchToFirstPersonMode();
        resolve();
    });
}

export function start360CameraRotation() {
    OCTREE.start360CameraRotation();
}
export function setCurrentAction(action) {
    changeState(Constants.STATE.HOST_ACTION);
    currentAction = action;
}
export function setSelectedUsers(users) {
    selectedUsers = users;

}
export function finishPathfindingMovement() {
    emit(Constants.EVENTS_NAME.FINISH_PATHFINDING_MOVEMENT);
}

export function checkCollisions(cube) {
    return OCTREE.checkCollisions(cube);
}

export function ghostMode(value) {
    OCTREE.ghostMode(value);
}

export function moveToPoint(point) {
    OCTREE.moveToPoint(point);
}

export function checkIntersectionsAroundObject(position) {
    const origin = position;
    const directions = [
        new THREE.Vector3(1, -1, 0),   // right
        new THREE.Vector3(-1, -1, 0),  // left
        new THREE.Vector3(0, -1, 1),   // forward
        new THREE.Vector3(0, -1, -1),  // backward
        new THREE.Vector3(1, -1, 1),   // diagonal forward-right
        new THREE.Vector3(-1, -1, 1),  // diagonal forward-left
        new THREE.Vector3(1, -1, -1),  // diagonal backward-right
        new THREE.Vector3(-1, -1, -1), // diagonal backward-left
    ];
    //visualizeRaycasts(origin, directions, 5);
    for (const direction of directions) {
        raycaster.set(origin, direction.normalize());
        const intersections = raycaster.intersectObjects(sceneCollider.children);
        if (intersections.length > 0) {
            if (getTypeOfSurface(intersections[0].normal) === 'floor') {
                PATHFINDING.moveTo(intersections[0].point, false);
                return;
            }
        }
        else {
            console.log("no intersecta");
        }

    }
    return;
}

function visualizeRaycasts(origin, directions, radius) {
    // Eliminar líneas anteriores si es necesario
    // ...

    const rayMaterial = new THREE.LineBasicMaterial({ color: 0xff0000 });
    directions.forEach(direction => {
        const normalizedDirection = direction.clone().normalize();
        const endPoint = origin.clone().add(normalizedDirection.multiplyScalar(radius));
        const rayGeometry = new THREE.BufferGeometry().setFromPoints([origin, endPoint]);
        const rayLine = new THREE.Line(rayGeometry, rayMaterial);
        scene.add(rayLine);
        // Guardar referencia si necesitas eliminarlas después
    });
}
function getTypeOfSurface(normal) {
    let surfaceType = 'floor';
    const wallThreshold = 0.1;

    if (Math.abs(normal.y - 1) < wallThreshold) {
        surfaceType = 'floor';
    } else if (Math.abs(normal.y + 1) < wallThreshold) {
        surfaceType = 'ceiling';
    } else {
        surfaceType = 'wall';
    }
    return surfaceType;
}

export function getSceneObject(uuid) {
    return TRANSFORM_CONTROL.getSceneObject(uuid);
}

export function getSizeObject(_obj) {
    return Utils.getSizeObject(_obj);
}