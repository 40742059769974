import { MSG_CODE } from "../constants";
import { v4 as uuidv4 } from 'uuid';
import { setRoomSpawn } from './networkingModuleController';
import { TaskQueue } from "../clases/TaskQueue";

let viewerModule;
let navigationModule;
let loaderWorkerInstancer;
let specs;
let useOffscreen = false;
let useWorkerLoader = false;
let offscreenCanvasWorker;
let taskQueue;
let maxParallelTasks = 30;

export let viewerWorkerPromises = {}

export function setViewerModules(_viwerModule, _navigationModule) {
    viewerModule = _viwerModule;
    navigationModule = _navigationModule;
    viewerModule.setNavigationModule(navigationModule);
}

export function createOffscreen(options) {
    offscreenCanvasWorker = options.offscreenCanvasWorker;
    useOffscreen = options.rendererOptions.useOffscreen;
    useWorkerLoader = options.rendererOptions.useWorkerLoader;
    loaderWorkerInstancer = options.loaderWorkerInstancer;
    specs = options.specs;
    
    viewerModule = options.modules.viewerModule;
    maxParallelTasks = specs.maxParallelTasks;

    taskQueue = new TaskQueue(maxParallelTasks);

    if (useOffscreen)
        viewerModule.initOffscreen(options);
    else {
        options.rendererOptions.inputElement = options.rendererOptions.canvas;
        viewerModule.init(options.rendererOptions, options.callbacks);
    }
}

export function viewerManageWorkerMessages(msg) {
    viewerModule.viewerManageWorkerMessages(msg);
}

export function manageRendererMessages(data) {
    return new Promise((resolve, reject) => {
        viewerModule.manageRendererMessages(data).then((uuid) => {
            postMessage({ code: MSG_CODE.CORE_RESOLVE_WORKER_VIEWER_PROMISE, uuid, lastCode: data.code });
            resolve();
        }).catch(reject);
    })
}


export function loadScene(json) {
    return new Promise((resolve, reject) => {
        const uuid = uuidv4();
        viewerWorkerPromises[uuid] = { resolve, reject };

        setRoomSpawn(json.spawn);

        const onAssetsLoaded = (assets) => {

            const buffers = [];
            assets.forEach(value => {
                buffers.push(value.buffer);
            });

            let msg = {
                code: MSG_CODE.ASSETS_LOAD_SCENE,
                data: {
                    assets,
                    features: json.features,
                    lights: json.lights,
                    spawn: json.spawn,
                    specs
                },
                uuid
            }

            if (useWorkerLoader)
                offscreenCanvasWorker.postMessage(msg, [...buffers]);
            else
                manageRendererMessages(msg).then(() => { });

        }

        if (useWorkerLoader)
            viewerModule.loadSceneAssets(json, specs, loaderWorkerInstancer).then(onAssetsLoaded);
        else
            viewerModule.loadSceneAssets(json, specs).then(onAssetsLoaded);

    });

}

export function performUIAction(msg) {
    switch (msg.code) {
        case MSG_CODE.VIEWER_SET_LIGHTS_ENABLED:
        case MSG_CODE.VIEWER_SET_HELPERS_ENABLED:
        case MSG_CODE.VIEWER_SET_FEATURES_ENABLED:
            offscreenCanvasWorker.postMessage(msg);
            break;
        default:
            break;
    }
}

export function deleteSceneObject(_msg) {

    return new Promise((resolve, reject) => {
        try {
            let validate = window;

            const promiseUuid = uuidv4();
            viewerWorkerPromises[promiseUuid] = { resolve, reject };

            let msg = {
                code: MSG_CODE.SCENE_OBJECT_DELETE,
                data: _msg.data,
                uuid: promiseUuid
            }


            if (useWorkerLoader)
                offscreenCanvasWorker.postMessage(msg);

        } catch (error) {
            viewerModule.deleteSceneObject(_msg.data).then(() => {

                let msg = {
                    code: 'viewerResolve',
                    data: {
                    },
                    uuid: _msg.uuid
                }

                postMessage(msg);
            }).catch(e => {
                console.log(e);
            })


        }

    });
}

export function getSetRemoteActor(_msg) {

    return new Promise((resolve, reject) => {
        try {
            let validate = window;

            const promiseUuid = uuidv4();
            viewerWorkerPromises[promiseUuid] = { resolve, reject };

            let msg = {
                code: MSG_CODE.VIP_GUEST_GET_REMOTE_USER,
                data: _msg.data,
                uuid: promiseUuid
            }


            if (useWorkerLoader)
                offscreenCanvasWorker.postMessage(msg);

        } catch (error) {
            viewerModule.setRemoteLinkpadPosition(_msg.data).then(() => {

                let msg = {
                    code: 'viewerResolve',
                    data: {
                    },
                    uuid: _msg.uuid
                }

                postMessage(msg);
            })


        }

    });
}


export function startFitToObject(_msg) {

    return new Promise((resolve, reject) => {
        try {
            let validate = window;

            const promiseUuid = uuidv4();
            viewerWorkerPromises[promiseUuid] = { resolve, reject };

            let msg = {
                code: MSG_CODE.START_FIT_OBJECT,
                data: _msg.data,
                uuid: promiseUuid
            }


            if (useWorkerLoader)
                offscreenCanvasWorker.postMessage(msg);

        } catch (error) {
            viewerModule.getLinkpadObject(_msg.data).then((model) => {
                navigationModule.startFitToObject(model);
                let msg = {
                    code: 'viewerResolve',
                    data: {},
                    uuid: _msg.uuid
                }

                postMessage(msg);
            })


        }

    });
}

export function stopFitToObject(_msg) {
    return new Promise((resolve, reject) => {
        try {
            let validate = window;

            const promiseUuid = uuidv4();
            viewerWorkerPromises[promiseUuid] = { resolve, reject };

            let msg = {
                code: MSG_CODE.STOP_FIT_OBJECT,
                data: {},
                uuid: promiseUuid
            }


            if (useWorkerLoader)
                offscreenCanvasWorker.postMessage(msg);

        } catch (error) {
            navigationModule.stopFitToObject();
            let msg = {
                code: 'viewerResolve',
                data: {},
                uuid: _msg.uuid
            }
            postMessage(msg);
        }

    });
}

export function getCameraPositionAndRotation(_msg) {
    return new Promise((resolve, reject) => {
        try {
            let validate = window;

            const promiseUuid = uuidv4();
            viewerWorkerPromises[promiseUuid] = { resolve, reject };

            let msg = {
                code: 'getCameraPosition',
                data: {},
                uuid: promiseUuid
            }


            if (useWorkerLoader)
                offscreenCanvasWorker.postMessage(msg);

        } catch (error) {
            viewerModule.getCameraPositionAndRotation().then((data) => {

                let msg = {
                    code: 'viewerResolve',
                    data,
                    uuid: _msg.uuid
                }

                postMessage(msg);
            })


        }

    });
}

export function getLocalActor(_msg) {
    return new Promise((resolve, reject) => {
        try {
            let validate = window;

            const promiseUuid = uuidv4();
            viewerWorkerPromises[promiseUuid] = { resolve, reject };

            let msg = {
                code: 'getLocalActor',
                data: {},
                uuid: promiseUuid
            }
            if (useWorkerLoader)
                offscreenCanvasWorker.postMessage(msg);
        } catch (error) {
            viewerModule.getLocalActor().then((data) => {
                let msg = {
                    code: 'viewerResolve',
                    data,
                    uuid: _msg.uuid
                }
                postMessage(msg);
            })
        }
    });
}

export function loadGalleryLODObject(_msg) {
    return taskQueue.enqueue(() => loadObjectBytes(_msg));
}

function loadObjectBytes(_msg) {
    return new Promise((resolve, reject) => {

        const worker = loaderWorkerInstancer.create();

        const msg = {
            code: MSG_CODE.SCENE_OBJECTS_LOAD,
            object: _msg
        }

        worker.postMessage(msg);

        worker.addEventListener('message', (workerResponse) => {
            const result = workerResponse.data;
            worker.terminate();
            resolve(result);
        });

        worker.onerror = (e) => { console.log('error en worker de carga'); reject(e) };

    });
}

export function createSceneObjectDisplay(_msg) {
    return new Promise((resolve, reject) => {
        try {
            let validate = window;

            const promiseUuid = uuidv4();
            viewerWorkerPromises[promiseUuid] = { resolve, reject };

            let msg = {
                code: 'createSceneObjectDisplay',
                data: _msg.data,
                uuid: promiseUuid
            }


            if (useWorkerLoader) {
                if (_msg.data.buffers)
                    offscreenCanvasWorker.postMessage(msg, [..._msg.data.buffers]);
                else
                    offscreenCanvasWorker.postMessage(msg);
            }

        } catch (error) {
            viewerModule.createSceneObjectDisplay(_msg).then(() => {

                let msg = {
                    code: 'viewerResolve',
                    data: {
                    },
                    uuid: _msg.uuid
                }

                postMessage(msg);
            })

        }

    });
}

export function placeSceneObjectDisplay(_msg) {
    return new Promise((resolve, reject) => {
        try {
            let validate = window;

            const promiseUuid = uuidv4();
            viewerWorkerPromises[promiseUuid] = { resolve, reject };

            let msg = {
                code: 'placeSceneObjectDisplay',
                data: _msg.data,
                uuid: promiseUuid
            }


            if (useWorkerLoader) {
                if (_msg.data.buffers)
                    offscreenCanvasWorker.postMessage(msg, [..._msg.data.buffers]);
                else
                    offscreenCanvasWorker.postMessage(msg);
            }

        } catch (error) {
            viewerModule.placeSceneObjectDisplay(_msg).then((res) => {

                let msg = {
                    code: 'viewerResolve',
                    data: {
                        res
                    },
                    uuid: _msg.uuid
                }

                postMessage(msg);
            })

        }

    });
}


export function createSceneObjectByBuffers(_msg) {
    return new Promise((resolve, reject) => {
        try {
            let validate = window;

            const promiseUuid = uuidv4();
            viewerWorkerPromises[promiseUuid] = { resolve, reject };

            let msg = {
                code: 'createSceneObjectByBuffers',
                data: _msg.data,
                uuid: promiseUuid
            }


            if (useWorkerLoader) {
                if (_msg.data.buffers)
                    offscreenCanvasWorker.postMessage(msg, [..._msg.data.buffers]);
                else
                    offscreenCanvasWorker.postMessage(msg);
            }

        } catch (error) {
            viewerModule.createSceneObjectByBuffers(_msg).then(() => {

                let msg = {
                    code: 'viewerResolve',
                    data: {
                    },
                    uuid: _msg.uuid
                }

                postMessage(msg);
            })

        }

    });
}

export function createLODSceneObjectsByBuffers(_msg) {
    return new Promise((resolve, reject) => {
        try {
            let validate = window;

            const promiseUuid = uuidv4();
            viewerWorkerPromises[promiseUuid] = { resolve, reject };

            let msg = {
                code: 'createLODSceneObjectsByBuffers',
                data: _msg.data,
                uuid: promiseUuid
            }


            if (useWorkerLoader) {
                offscreenCanvasWorker.postMessage(msg, [..._msg.data.buffers]);
            }

        } catch (error) {
            viewerModule.createLODSceneObjectsByBuffers(_msg).then(() => {

                let msg = {
                    code: 'viewerResolve',
                    data: {
                    },
                    uuid: _msg.uuid
                }

                postMessage(msg);
            })

        }

    });
}

export function placeLoadingObject(_msg) {
    return new Promise((resolve, reject) => {
        try {
            let validate = window;
            const promiseUuid = uuidv4();
            viewerWorkerPromises[promiseUuid] = { resolve, reject };

            let msg = {
                code: 'placeLoadingObject',
                data: _msg.data,
                uuid: promiseUuid
            }


            if (useWorkerLoader)
                offscreenCanvasWorker.postMessage(msg);

        } catch (error) {
            viewerModule.createLoadingIndicator(_msg).then(() => {
                let msg = {
                    code: 'viewerResolve',
                    data: {
                    },
                    uuid: _msg.uuid
                }

                postMessage(msg);
            })

        }

    });
}

export function placeSceneObject(_msg) {
    return new Promise((resolve, reject) => {
        try {
            let validate = window;
            const promiseUuid = uuidv4();
            viewerWorkerPromises[promiseUuid] = { resolve, reject };

            let msg = {
                code: 'placeSceneObject',
                data: _msg.data,
                uuid: promiseUuid
            }


            if (useWorkerLoader)
                offscreenCanvasWorker.postMessage(msg);

        } catch (error) {
            viewerModule.placeSceneObject(_msg).then((res) => {
                let msg = {
                    code: 'viewerResolve',
                    data: {
                        res
                    },
                    uuid: _msg.uuid
                }

                postMessage(msg);
            }).catch(e => {
                let msg = {
                    code: 'viewerReject',
                    data: {
                    },
                    uuid: _msg.uuid
                }

                postMessage(msg);
            })

        }

    });
}

export function remoteUpdateSceneObject(_msg) {
    return new Promise((resolve, reject) => {
        try {
            let validate = window;
            const promiseUuid = uuidv4();
            viewerWorkerPromises[promiseUuid] = { resolve, reject };

            let msg = {
                code: 'remoteUpdateSceneObject',
                data: _msg.data,
                uuid: promiseUuid
            }


            if (useWorkerLoader)
                offscreenCanvasWorker.postMessage(msg);

        } catch (error) {
            viewerModule.remoteUpdateSceneObject(_msg).then(() => {
                let msg = {
                    code: 'viewerResolve',
                    data: {},
                    uuid: _msg.uuid
                }

                postMessage(msg);
            })

        }

    });
}

export function remoteDeleteSceneObject(_msg) {
    return new Promise((resolve, reject) => {
        try {
            let validate = window;
            const promiseUuid = uuidv4();
            viewerWorkerPromises[promiseUuid] = { resolve, reject };

            let msg = {
                code: 'remoteDeleteSceneObject',
                data: _msg.data,
                uuid: promiseUuid
            }


            if (useWorkerLoader)
                offscreenCanvasWorker.postMessage(msg);

        } catch (error) {
            viewerModule.remoteDeleteSceneObject(_msg).then(() => {
                let msg = {
                    code: 'viewerResolve',
                    data: {
                    },
                    uuid: _msg.uuid
                }

                postMessage(msg);
            })

        }

    });
}

export function setVideoTextureToprojector(_msg) {
    return new Promise((resolve, reject) => {
        try {
            let validate = window;
            const promiseUuid = uuidv4();
            viewerWorkerPromises[promiseUuid] = { resolve, reject };

            let msg = {
                code: 'setVideoTextureToprojector',
                data: _msg.data,
                uuid: promiseUuid
            }

            if (useWorkerLoader)
                offscreenCanvasWorker.postMessage(msg, [_msg.data.bitmap]);

        } catch (error) {
            viewerModule.setVideoTextureToprojector(_msg).then(() => {
                let msg = {
                    code: 'viewerResolve',
                    data: {},
                    uuid: _msg.uuid
                }
                postMessage(msg);
            })
        }
    });
}

export function resetProjector(_msg) {
    return new Promise((resolve, reject) => {
        try {
            let validate = window;
            const promiseUuid = uuidv4();
            viewerWorkerPromises[promiseUuid] = { resolve, reject };

            let msg = {
                code: 'resetProjector',
                data: _msg.data,
                uuid: promiseUuid
            }


            if (useWorkerLoader)
                offscreenCanvasWorker.postMessage(msg);

        } catch (error) {
            viewerModule.resetProjector(_msg).then(() => {
                let msg = {
                    code: 'viewerResolve',
                    data: {
                    },
                    uuid: _msg.uuid
                }
                postMessage(msg);
            })
        }
    });
}

export function createInstancedMeshLODSceneObjectFromDB(_msg) {
    return new Promise((resolve, reject) => {
        try {
            let validate = window;

            const promiseUuid = uuidv4();
            viewerWorkerPromises[promiseUuid] = { resolve, reject };

            let msg = {
                code: 'createInstancedMeshLODSceneObjectFromDB',
                data: _msg.data,
                uuid: promiseUuid
            }


            if (useWorkerLoader) {
                offscreenCanvasWorker.postMessage(msg, [..._msg.data.buffers]);
            }

        } catch (error) {
            viewerModule.createInstancedMeshLODSceneObjectFromDB(_msg).then(() => {

                let msg = {
                    code: 'viewerResolve',
                    data: {
                    },
                    uuid: _msg.uuid
                }

                postMessage(msg);
            }).catch(e => {
                console.log('error', e);
                let msg = {
                    code: 'viewerReject',
                    data: {
                    },
                    uuid: _msg.uuid
                }

                postMessage(msg);
            })

        }

    });
}

export function createNotFoundObject(_msg) {
    return new Promise((resolve, reject) => {
        try {
            let validate = window;

            const promiseUuid = uuidv4();
            viewerWorkerPromises[promiseUuid] = { resolve, reject };

            let msg = {
                code: 'createNotFoundObject',
                data: _msg.data,
                uuid: promiseUuid
            }


            offscreenCanvasWorker.postMessage(msg);

        } catch (error) {
            viewerModule.createNotFoundObject(_msg).then(() => {

                let msg = {
                    code: 'viewerResolve',
                    data: {
                    },
                    uuid: _msg.uuid
                }

                postMessage(msg);
            })

        }

    });
}

export function createInstancedMeshLODSceneObject(_msg) {
    return new Promise((resolve, reject) => {
        try {
            let validate = window;

            const promiseUuid = uuidv4();
            viewerWorkerPromises[promiseUuid] = { resolve, reject };

            let msg = {
                code: 'createInstancedMeshLODSceneObject',
                data: _msg,
                uuid: promiseUuid
            }


            if (useWorkerLoader) {
                if (_msg.cached) {
                    offscreenCanvasWorker.postMessage(msg);
                } else {
                    offscreenCanvasWorker.postMessage(msg, [..._msg.buffers]);
                }
            }

        } catch (error) {
            viewerModule.createInstancedMeshLODSceneObject(_msg).then(() => {

                let msg = {
                    code: 'viewerResolve',
                    data: {
                    },
                    uuid: _msg.uuid
                }

                postMessage(msg);
            }).catch(e => {
                let msg = {
                    code: 'viewerReject',
                    data: {
                    },
                    uuid: _msg.uuid
                }

                postMessage(msg);

            })

        }

    });
}

export function setRoomElementsForOrbit(_msg) {
    return new Promise((resolve, reject) => {
        try {
            //DOM      
            let validate = window;
            // console.log('setRoomElementsForOrbit', _msg);            
            const uuidPromise = uuidv4();
            viewerWorkerPromises[uuidPromise] = { resolve, reject };
            let msg = {
                code: 'setRoomElementsForOrbit',
                data: _msg.data,
                uuid: uuidPromise
            }

            if (useWorkerLoader)
                offscreenCanvasWorker.postMessage(msg);

        } catch (error) {
            viewerModule.setRoomElementsForOrbit(_msg).then(() => {
                let msg = {
                    code: 'viewerResolve',
                    data: {},
                    uuid: _msg.uuid
                }
                postMessage(msg);
            })
        }
    })
}

export function updateLinkpad(_msg) {
    console.log(_msg);
    return new Promise((resolve, reject) => {
        try {
            let validate = window;

            const promiseUuid = uuidv4();
            viewerWorkerPromises[promiseUuid] = { resolve, reject };

            let msg = {
                code: 'updateLinkpad',
                data: _msg.data,
                uuid: promiseUuid
            }
            
            offscreenCanvasWorker.postMessage(msg);
            

        } catch (error) {
            viewerModule.updateLinkpad(_msg).then(() => {

                let msg = {
                    code: 'viewerResolve',
                    data: {
                    },
                    uuid: _msg.uuid
                }

                postMessage(msg);
            })

        }

    });
}

export function onSceneObjectUpdated(objectData) {
    viewerModule.onSceneObjectUpdated(objectData);
}

export function onSceneObjectChange(objectData) {
    viewerModule.onSceneObjectChange(objectData);
}

export function onSceneObjectSelect(objectData) {
    viewerModule.onSceneObjectSelect(objectData);
}

export function setLightsEnabled(value) {
    viewerModule.setLightsEnabled(value);
}

export function setHelpersEnabled(value) {
    viewerModule.setHelpersEnabled(value);
}

export function setFeaturesEnabled(value) {
    viewerModule.setFeaturesEnabled(value);
}

export function remoteUserJoinned(msg) {
    viewerModule.onRemoteUserJoinned(msg);
}

export function setVolumeIndicator(msg) {
    viewerModule.setVolumeIndicator(msg);
}

export function setRemoteUserSpawnSetup(msg) {
    viewerModule.setRemoteUserSpawnSetup(msg);
}

export function updateRemoteLinkpads(msg) {
    viewerModule.updateRemoteLinkpads(msg);
}

export function removeLinkpad(msg) {
    viewerModule.removeLinkpad(msg);
}

export function spawnUser(data) {
    viewerModule.localSpawn(data).then(() => {
        postMessage({ code: MSG_CODE.ROOM_USER_SPAWN_DONE });
    });
}

export function remoteUserSpawned(data) {
    viewerModule.remoteUserSpawned(data);
}

export function getCameraPosition(data) {
    viewerModule.getCameraPosition(data);
}

export function setRemoteSpawnPosition(data) {
    viewerModule.setRemoteSpawnPosition(data);
}

export function setLinkpadVideo(data) {
    viewerModule.setLinkpadVideo(data);
}

export function setVideoEnabledOnLinkpad(data) {
    viewerModule.setVideoEnabledOnLinkpad(data);
}

export function setAudioEnabledOnLinkpad(data) {
    viewerModule.setAudioEnabledOnLinkpad(data);
}


export function viewerResolve(msg) {
    viewerWorkerPromises[msg.uuid].resolve(msg.data);
}

/*
export function updateLinkpadNameResolve(msg) {
    viewerWorkerPromises[msg.uuid].resolve(msg.data);
}
    */

export function viewerReject(msg) {
    viewerWorkerPromises[msg.uuid].reject(msg.data);
}

export function setFollow(msg) {
    viewerModule.setFollow(msg);
}

export function setUnFollow() {
    viewerModule.setUnFollow();
}

export function showSceneObjectPreview(data) {
    viewerModule.showSceneObjectPreview(data);
}

export function hideSceneObjectPreview() {
    viewerModule.hideSceneObjectPreview();
}

export function showDefaultCursor(data) {
    viewerModule.showDefaultPointer(data).then((surface) => {
        viewerModule.showDefaultCursor(surface).then((cursorURL) => {
            if (cursorURL){
                // console.log('change 2d   cursor');
                postMessage({ code: MSG_CODE.CHANGE_CURSOR, cursorURL });
            } 
        });
    });
}

export function hideDefaultCursor() {
    viewerModule.hideDefaultPointer().then(() => {

    });
}

export function showCreateObjectCursor(data) {
    viewerModule.showCreateObjectPointer(data);
    viewerModule.hideCursor().then((cursorURL) => {
        if (cursorURL) postMessage({ code: MSG_CODE.CHANGE_CURSOR, cursorURL });
    });
}

export function showHoverObjectCursor() {
    viewerModule.showHoverObjectCursor().then((cursorURL) => {
        if (cursorURL) postMessage({ code: MSG_CODE.CHANGE_CURSOR, cursorURL });
    });
}

export function updateActionPointerData(msg) {
    viewerModule.updateActionPointer(msg);
}

export function setPointerVisibility(value) {
    viewerModule.setPointerVisibility(value);
}

export function getRemoteLinkpadPosition(msg){
    const pos = viewerModule.getRemoteLinkpadPosition(msg.data.userId);
    navigationModule.checkIntersectionsAroundObject(pos);
}